import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue, { BootstrapVueIcons, IconsPlugin } from "bootstrap-vue";
import VueSignaturePad from 'vue-signature-pad';
import VueLazyload from 'vue-lazyload';
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import VueSilentbox from 'vue-silentbox';
//import JsonVuer from 'json-vuer';
import JsonViewer from 'vue-json-viewer'

Vue.use(VueSignaturePad);

Vue.config.productionTip = false;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVueIcons)

Vue.use(VueLazyload)

Vue.use(VueSilentbox)

Vue.use(JsonViewer)

//Vue.use(JsonVuer)

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
