<template>
<div>
  <h4>Vorbereitungen zum Testen</h4>
  <b-form  v-if="!erroredSubmitting">
    <b-form-group
      v-if="!erroredLoadingTestkits"
      class="my-4 col-12 col-md-6"
      label="Wähle das heutige Testkit aus"
    >
      <b-form-select class="col-5" v-model="selectedTestKit" :options="testKits"></b-form-select>
    </b-form-group>
    <b-alert variant="danger" v-else :show="erroredLoadingTestkits">
      Beim Laden der Testkits trat ein Fehler auf
    </b-alert>

    <b-form-group
        class="my-4 col-12 col-md-6"
        id="input-group-tester-name"
        label="Tester Vor- und Nachname:"
        label-for="input-tester-name"
      >
        <b-form-input
          id="input-tester-name"
          v-model="testerName"
          type="text"
          placeholder="Gebe den Vor- und Nachnamen des Testers ein"
          required
        ></b-form-input>
      </b-form-group>

    <b-form-group
      class="my-4 col-12 col-md-6"
      label="Unterschrift, die auf dem Testergebnis dargestellt wird"
    >
      <VueSignaturePad height="200px" class="col-12" ref="signaturePad" :customStyle="this.style"/>
    </b-form-group>

    <div class="d-flex flex-column">
      <b-button :disabled="!selectedTestKit || testerName === ''" class="col-12 col-md-3 my-2" variant="success" @click="safe">Daten speichern</b-button>
      <b-button class="col-12 col-md-3 my-2" variant="outline-secondary" @click="undo">Rückgängig machen</b-button>
      <p></p>
      <b-button class="col-12 col-md-3 my-2" variant="info" @click="skip">Überspringen (keine Testungen)</b-button>
    </div>
  </b-form>
  <b-alert variant="danger" v-else :show="erroredSubmitting">
    Beim Speichern trat ein Fehler auf:
    <p>{{error}}</p>
  </b-alert>
</div>
</template>

<script>
import { getTestKits, setSessionSettings, setValidPLZ } from "@/client";

export default {
  name: "PrepareTestCenterComponent",
  beforeMount() {
    this.loadTestKits();
    this.setValidPLZs();
  },
  data () {
    return {
      style: {
        border: "#ced4da 1px solid",
        borderRadius: "5px",
        minHeight: "100px",
        maxWidth: "637px",
      },
      error: null,
      erroredLoadingTestkits: false,
      erroredSubmitting: false,
      testKits: [],
      testerName: "",
      selectedTestKit: null,
    }
  },
  methods: {
    loadTestKits() {
      getTestKits((testkitData, error) => {
        if(error) {
          this.error = error;
          this.erroredLoadingTestkits = true;
        } else {
          const testkits = [
            {
              value: null,
              text: 'Wähle ein Testkit'
            }
          ];
          testkitData.map((testkit) => {
            const newTestkit = {
              value: testkit.testkit_id,
              text: testkit.testkit_device_id + ' / ' + testkit.testkit_name + '; ' + testkit.testkit_hersteller
            }
            testkits.push(newTestkit);
          });
          this.testKits = testkits;
        }
      })
    },
    setValidPLZs() {
      setValidPLZ((validPLZ, error) => {
        if(error){
          this.error = error;
          this.errored = true;
        } else {
          this.$store.dispatch('setValidPLZ', validPLZ);
        }
      });
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    safe() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(isEmpty) {
        this.style.border = "#dc3545 1px solid"
      } else {
        this.signatureData = data;
        setSessionSettings(this.selectedTestKit, this.testerName, this.signatureData, (response, error) => {
          if(error) {
            this.erroredSubmitting = true;
            this.error = error;
          } else {
            const testkit = this.testKits.find(element => element.value === this.selectedTestKit)
            console.log(testkit)
            this.$store.dispatch('setIsTestCenterPrepared', {isTestCenterPrepared: true, selectedTestkit: testkit.text});
          }
        });
      }
    },
    skip() {
      this.$store.dispatch('setIsTestCenterPrepared', {isTestCenterPrepared: true, selectedTestkit: 'KEIN TESTKIT'});
    }
  }
};
</script>

<style scoped>

</style>
