import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const getDefaultState = {
  userToken: null,
  isAuthenticated: null,
  testCenter: {
    id: "",
    name: "",
    locations: [],
    selectedLocation: "",
    isTestCenterPrepared: false,
    selectedTestkit: null,
  },
  validPLZ: null,
  registrationLocation: null,
  appointments: [],
}

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: getDefaultState,
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getTestCenterName(state) {
      return state.testCenter.name;
    },
    getLocationFromIndex: (state) => (index) => {
      return state.testCenter.locations[index];
    },
    getLocationFromId: (state) => (id) => {
      return state.testCenter.locations.find((location) => location.id === id);
    },
    getLocationIndexFromId: (state) => (id) => {
      let oldLocation = state.testCenter.locations.find((location) => location.id === id);
      return state.testCenter.locations.indexOf(oldLocation);
    },
    getHighestCustomerId: (state) => {
      let highestId = 0;
      state.appointments.map((appointment) => {
        if(parseInt(highestId) < parseInt(appointment.kunde_id)) {
          highestId = appointment.kunde_id
        }
      });
      return highestId;
    },
    getAppointmentIndex: (state) => (customerId) => {
      let index = 0;
      state.appointments.map((appointment) => {
        if(appointment.kunde_id === customerId) {
          index = state.appointments.indexOf(appointment);
        }
      });
      return index;
    },
    checkValidPLZ: (state) => (plz) => {
      console.log(plz);
      return state.validPLZ.includes(plz);
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState)
    },
    setTestCenter(state, testCenter) {
      testCenter.selectedTestkit = state.testCenter.selectedTestkit;
      testCenter.isTestCenterPrepared = state.testCenter.isTestCenterPrepared;
      state.testCenter = testCenter;
    },
    setUserToken(state, userToken) {
      state.userToken = userToken;
      state.isAuthenticated = !!userToken;
    },
    setSelectedLocation(state, selectedLocation) {
      state.testCenter.selectedLocation = selectedLocation;
    },
    setLocation(state, payload) {
      state.testCenter.locations[payload.index] = payload.newLocation;
    },
    setAppointments(state, appointments) {
      state.appointments = appointments;
    },
    setFinishedAppointments(state, appointments) {
      state.finishedAppointments = appointments;
    },
    setAppointmentState(state, payload) {
      const oldAppointment = state.appointments[payload.index];
      const appointment = {
        state: payload.state,
        k_durchfuehrung_bestaetigt: oldAppointment.k_durchfuehrung_bestaetigt,
        k_geburtstag: oldAppointment.k_geburtstag,
        k_getestet_zeitstempel: oldAppointment.k_getestet_zeitstempel,
        k_legitimiert: oldAppointment.k_legitimiert,
        k_nachname: oldAppointment.k_nachname,
        k_vorname: oldAppointment.k_vorname,
        kunde_id: oldAppointment.kunde_id,
        teststelle_id: oldAppointment.teststelle_id
      };
      Vue.set(state.appointments, payload.index, appointment)
    },
    setRegistrationLocation(state, location) {
      state.registrationLocation = location;
    },
    setReadyToTest(state, appointmentIndex) {
      const oldAppointment = state.appointments[appointmentIndex];
      const oldAppointmentStatus = state.appointments[appointmentIndex]['k_status'];
      Vue.set(oldAppointment, 'k_durchfuehrung_bestaetigt', 'true');
      Vue.set(oldAppointmentStatus, 'k_durchfuehrung_bestaetigt', 'true')
    },
    setLegitimation(state, appointmentIndex) {
      const oldAppointment = state.appointments[appointmentIndex];
      const oldAppointmentStatus = state.appointments[appointmentIndex]['k_status'];
      Vue.set(oldAppointment, 'k_legitimiert', 'true');
      Vue.set(oldAppointmentStatus, 'k_legitimiert', 'true')
    },
    setValidPLZ(state, validPLZ) {
      state.validPLZ = validPLZ;
    },
    updateCustomer(state, payload) {
      let searchAppointment;
      let appointmentIndex;

      state.appointments.map((appointment, index) => {
        if (appointment.kunde_id === payload.kunde_id) {
          searchAppointment = appointment;
          appointmentIndex = index;
        }
      });
      const oldAppointment = state.appointments[appointmentIndex];
      console.log(payload);
      Vue.set(oldAppointment, 'k_vorname', payload.k_vorname);
      Vue.set(oldAppointment, 'k_nachname', payload.k_nachname);
      Vue.set(oldAppointment, 'k_geburtstag', payload.k_geburtstag);
      Vue.set(oldAppointment, 'k_strasse', payload.k_strasse);
      Vue.set(oldAppointment, 'k_plz', payload.k_plz);
      Vue.set(oldAppointment, 'k_ort', payload.k_ort);
      Vue.set(oldAppointment, 'k_telefon', payload.k_telefon);
      Vue.set(oldAppointment, 'k_ausweisnummer', payload.k_ausweisnummer);
      Vue.set(oldAppointment, 'k_testgrund_detail_desc', payload.k_testgrund_detail_desc);
    },
    updateEditCustomer(state, payload) {
      let searchAppointment;
      let appointmentIndex;

      state.finishedAppointments.map((appointment, index) => {
        if (appointment.kunde_id === payload.kunde_id) {
          searchAppointment = appointment;
          appointmentIndex = index;
        }
      });
      const oldAppointment = state.finishedAppointments[appointmentIndex];
      console.log(payload);
      Vue.set(oldAppointment, 'k_vorname', payload.k_vorname);
      Vue.set(oldAppointment, 'k_nachname', payload.k_nachname);
      Vue.set(oldAppointment, 'k_geburtstag', payload.k_geburtstag);
      Vue.set(oldAppointment, 'k_strasse', payload.k_strasse);
      Vue.set(oldAppointment, 'k_plz', payload.k_plz);
      Vue.set(oldAppointment, 'k_ort', payload.k_ort);
      Vue.set(oldAppointment, 'k_telefon', payload.k_telefon);
      Vue.set(oldAppointment, 'k_ausweisnummer', payload.k_ausweisnummer);
      Vue.set(oldAppointment, 'k_testgrund_detail_desc', payload.k_testgrund_detail_desc);
    },
    addNewCustomer(state, newCustomer) {
      const status = {
        kunde_id: newCustomer.kunde_id,
        k_durchfuehrung_bestaetigt: null,
        k_getestet_zeitstempel: null,
        k_legitimiert: null,
      }

      const appointment = {
        k_durchfuehrung_bestaetigt: null,
        k_geburtstag: newCustomer.k_geburtstag,
        k_getestet_zeitstempel: null,
        k_legitimiert: null,
        k_nachname: newCustomer.k_nachname,
        k_status: status,
        k_vorname: newCustomer.k_vorname,
        kunde_id: newCustomer.kunde_id,
        teststelle_id: newCustomer.teststelle_id
      };

      let searchAppointment;
      let appointmentIndex;
      state.appointments.map((appointment, index) => {
        if (appointment.kunde_id === newCustomer.kunde_id) {
          searchAppointment = appointment;
          appointmentIndex = index;
        }
      });

      state.appointments.push(appointment);
      Vue.set(state.appointments, appointmentIndex, appointment);
    },
    setIsTestCenterPrepared(state, payload) {
      state.testCenter = {
        id: state.testCenter.id,
        name: state.testCenter.name,
        locations: state.testCenter.locations,
        selectedLocation: state.testCenter.selectedLocation,
        isTestCenterPrepared: payload.isTestCenterPrepared,
        selectedTestkit: payload.selectedTestkit,
      };
    },
    removeCustomer(state, customerId) {
      let i = state.appointments.map(appointment => appointment.kunde_id).indexOf(customerId);
      state.appointments.splice(i, 1);
    },
    setTimeStampTest(state, payload) {
      let searchAppointment;

      state.appointments.map((appointment) => {
        if (appointment.kunde_id === payload.customerId) {
          searchAppointment = appointment;
        }
      });
      Vue.set(searchAppointment.k_status, 'k_getestet_zeitstempel', payload.k_getestet_zeitstempel);
      Vue.set(searchAppointment, 'k_getestet_zeitstempel', payload.k_getestet_zeitstempel);
    }
  },
  actions: {
    setTestCenter({ commit }, testCenter) {
      commit("setTestCenter", testCenter);
    },
    setUserToken({ commit }, userToken) {
      commit("setUserToken", userToken);
    },
    setAppointments({commit}, appointments) {
      commit("setAppointments", appointments);
    },
    setFinishedAppointments({commit}, appointments) {
      commit("setFinishedAppointments", appointments);
    },
    setAppointmentState({commit}, payload) {
      commit('setAppointmentState', payload);
    },
    setRegistrationLocation({commit}, location) {
      commit("setRegistrationLocation", location)
    },
    setReadyToTest({commit}, appointmentIndex) {
      commit("setReadyToTest", appointmentIndex);
    },
    setLegitimation({commit}, appointmentIndex) {
      commit("setLegitimation", appointmentIndex);
    },
    addNewCustomer({commit}, newCustomer) {
      commit('addNewCustomer', newCustomer);
    },
    setIsTestCenterPrepared({commit}, payload) {
      commit('setIsTestCenterPrepared', payload)
    },
    removeCustomer({commit}, customerId) {
      commit('removeCustomer', customerId);
    },
    setTimeStampTest({commit}, payload) {
      commit('setTimeStampTest', payload);
    },
    setValidPLZ({commit}, validPLZ) {
      commit('setValidPLZ', validPLZ);
    }
  },
  modules: {},
});
