<template>
  <div id="app" class="container">
    <HeaderComponent/>
    <router-view />
  </div>
</template>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-bottom: 7rem;
}
</style>
<script>
import HeaderComponent from "@/components/HeaderComponent";
export default {
  components: { HeaderComponent }
}
</script>
