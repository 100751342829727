<template>
  <div>
    <LoginComponent v-if="!this.$store.state.isAuthenticated"></LoginComponent>
    <div v-else>
      <div v-if="!this.$store.state.testCenter.isTestCenterPrepared">
        <prepare-test-center-component/>
      </div>
      <div v-else>

      <b-container>
        <b-row>
          <b-col cols="8">
            <h4>Willkommen in der Übersicht der Standorte des Testzentrums {{testCenterName}}</h4>
            <h6>Wählen Sie einen Standort aus</h6>
          </b-col>
          <b-col cols="2">
            <b-button variant="outline-secondary" @click="getCount()">Gesamtzahlen</b-button>
          </b-col>
          <b-col cols="2">
            <b-button variant="outline-secondary" @click="getEditCustomer()">Kunden (letzte 48h)</b-button>
          </b-col>
        </b-row>
      </b-container>
      
        <test-center-location-list/>
      </div>
    </div>
  </div>
</template>

<script>
import LoginComponent from "@/components/LoginComponent";
import TestCenterLocationList from "@/components/TestCenterLocationList";
import PrepareTestCenterComponent from "@/components/PrepareTestCenterComponent";
import { getCount, getEditCustomerJSON } from "@/client";
export default {
  name: "TestCenterHomeView",
  components: { PrepareTestCenterComponent, TestCenterLocationList, LoginComponent, getCount },
  data() {
    return {
      testCenterName: this.$store.state.testCenter.name,
    };
  },
  methods: {
    getCount() {
      this.$router.push({path: '/count'});
    },
    getEditCustomer() {
      this.$router.push({path: '/editCustomer'});
    }
  },
};
</script>
