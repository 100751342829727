import Vue from "vue";
import VueRouter from "vue-router";
import TestCenterHomeView from "../views/TestCenterHomeView.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "testCenterHomeView",
    component: TestCenterHomeView,
  },
  {
    path: "/registration/:id",
    name: "customerRegistrationView",
    component: () => import("../views/CustomerRegistrationView.vue"),
  },
  {
    path: "/edit/location/:id",
    name: "testCenterEditView",
    component: () => import("../views/TestCenterEditView"),
  },
  {
    path: "/customer/:customerId/accept/:code",
    name: "customerAcceptanceView",
    component: () => import("../views/CustomerAcceptanceView"),
  },
  {
    path: "/location/:locationId/appointments",
    name: "testCenterAppointmentsListView",
    component: () => import("../views/TestCenterAppointmentsListView"),
  },
  {
    path: "/count",
    name: "countView",
    component: () => import("../views/CountView"),
  },
    {
    path: "/editCustomer",
    name: "editCustomerView",
    component: () => import("../views/EditCustomerView"),
  }
];

const router = new VueRouter({
  routes,
});

router.beforeResolve((to, from, next) => {
  if(!isAuthenticated(to.name)) next({name: "testCenterHomeView"})
  else next()
})

function isAuthenticated(toName) {
  if(store.getters.isAuthenticated) {
    return true;
  }
  if(toName === "testCenterHomeView") {
    return true;
  }
  if(toName === "customerRegistrationView") {
    return true;
  }
  if(toName === "customerAcceptanceView") {
    return true;
  }

  return false;
}

export default router;
