<template>
  <div class="d-flex justify-content-between my-3">
    <nav>
      <!-- <router-link to="/">Home</router-link> -->
      <!--  | -->
      <!-- <router-link to="/registration/4">Registration</router-link> -->
      <a href="https://dn-deliveries.de/erklaerung.pdf">Datenschutz</a>
      |
      <a href="https://dn-deliveries.de/impressum.html">Impressum</a>
    </nav>
    <b-button variant="outline-danger" size="sm" v-if="$store.getters.isAuthenticated" @click="logout()"
    >Abmelden</b-button
    >
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  methods: {
    logout() {
      sessionStorage.clear();
      this.$store.commit('resetState');
      this.$router.push("/");
    }
  }
};
</script>

<style scoped>

</style>
