const axios = require('axios');
const FormData = require('form-data');
axios.defaults.timeout = 1200 * 1000;

const storage = window.sessionStorage;

const protocol = "https://"
const host = "xenodochial-haslett.82-165-176-243.plesk.page"

const bearer = 'Bearer ';
let adminId = storage.getItem('adminId');
let userSessionToken = storage.getItem('userSessionToken');
let sessionId = storage.getItem('sessionId');

function loginUser(email, password, callback) {
  const route = '/session.php'

  const credentials = email + ':' + password;
  const headerValue = window.btoa(unescape(encodeURIComponent(credentials)));
  const completeHeader = 'Basic ' + headerValue;

  //console.log(credentials)
  axios.get(protocol+host+route, {
    headers: {
      'Authorization': completeHeader,
      'Access-Control-Allow-Headers': "*",
    }
  }).then((response) => {
    console.log(response);
    const admin_id = response.data.split(":")[0].substring(1);
    const sessionToken = response.data.split(":")[1];
    callback(admin_id, null);
    callback(sessionToken, null);

    storage.setItem('adminId', admin_id);
    adminId = storage.getItem('adminId');

    storage.setItem('userSessionToken', sessionToken);
    userSessionToken = storage.getItem('userSessionToken');

/*    axios.get(protocol+host+"/test_session.php", {
      headers: {
        'Authorization': bearer + userSessionToken,
        'Access-Control-Allow-Headers': "*",
      }
    }).then((response) => {
      console.log(response)
    }).catch((error) => {
      console.log(error);
    })*/

  }).catch((error) => {
    callback(null, error)
    console.log(error);
  })
}

function getLocations(callback) {
  const route = "/get_teststellen.php"
  axios.get(protocol + host + route, {
    headers: {
      accept: '*/*',
      'Authorization': bearer + userSessionToken
    },
    params: {
      admin_id: storage.getItem('adminId')
    },
  }).then((response) => {
    const locations = [];
    console.log(response);
    response.data.map((teststelle) => {
      const location = {
        id: teststelle.teststelle_id,
        name: teststelle.t_name,
        street: teststelle.t_strasse,
        postcode: teststelle.t_plz,
        city: teststelle.t_ort,
      };
      locations.push(location);
    })
    const testCenter = {
      id: "0000001",
      name: "",
      locations: locations
    }
    callback(testCenter, null)
  }).catch(function (error) {
    callback(null, error)
  })
}

function getDailyReportFacts(locationId, date, callback) {
  const route = "/get_count_per_testcenter.php"
  axios.get(protocol+host+route, {
    headers: {
      'Authorization': bearer + userSessionToken
    },
    params: {
      teststelle_id: locationId,
      k_anmeldedatum: date
    }
  }).then((response) => {
    callback(response.data, null)
  }).catch((error) => {
    callback(null, error)
  })
}

function getCWA(locationId, date, callback) {
  const route = "/get_cwa_per_testcenter.php"
  axios.get(protocol+host+route, {
    headers: {
      'Authorization': bearer + userSessionToken
    },
    params: {
      teststelle_id: locationId,
      k_anmeldedatum: date
    }
  }).then((response) => {
    callback(response.data, null)
  }).catch((error) => {
    callback(null, error)
  })
}

function getAppointments(locationId, date, callback) {
  const route = "/get_offene_termine.php"
  axios.get(protocol+host+route, {
    headers: {
      'Authorization': bearer + userSessionToken
    },
    params: {
      teststelle_id: locationId,
      k_anmeldedatum: date
    }
  }).then((response) => {
    callback(response.data, null)
  }).catch((error) => {
    callback(null, error)
  })
}

function registerAppointment(locationId, customer, date, checkInformation, checkCWA, callback) {
  const route = "/put_termin.php";
  const formData = new FormData();

  formData.append("teststelle_id", locationId);
  formData.append("k_vorname", customer.name);
  formData.append("k_nachname", customer.lastName);
  formData.append("k_strasse", customer.street);
  formData.append("k_plz", customer.postcode);
  formData.append("k_ort", customer.city);
  formData.append("k_telefon", customer.telephone);
  formData.append("k_email", customer.email);
  formData.append("k_geburtstag", customer.birthdate);
  formData.append("k_geburtsort", customer.birthplace);
  formData.append("k_ausweisnummer", customer.idNumber);
  formData.append("k_ausweisart", customer.ausweisart);
  formData.append("k_unterschrift1", customer.signatureData);
  formData.append("k_freitesten", customer.checkFreitesten);
  formData.append("k_datenschutz_einverstanden", checkInformation);
  formData.append("k_cwa_einverstanden", checkCWA);
  formData.append("k_anmeldedatum", date);
  formData.append("k_testgrund_detail", customer.testgrund);
  formData.append("fileList", customer.fileList);
  formData.append("k_testgrund_detail_desc", customer.testgrund_detail_desc);

  formData.append("k_vorname_vertreter", customer.name_vertreter);
  formData.append("k_nachname_vertreter", customer.lastName_vertreter);
  formData.append("k_geburtstag_vertreter", customer.birthdate_vertreter);
  formData.append("k_strasse_vertreter", customer.street_vertreter);
  formData.append("k_plz_vertreter", customer.postcode_vertreter);
  formData.append("k_ort_vertreter", customer.city_vertreter);

  axios.post(protocol + host + route, formData,
    {
      headers: formData.getHeaders
    }).then((response) => {
    callback(response, null);
  }).catch((error) => {
    callback(null, error);
  })
}

function uploadNachweis(fn, blob_data, callback) {
  const route = "/put_nachweis.php";
  const formData = new FormData();

  var MyDate = new Date();
  var datum = MyDate.getFullYear() + '' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '' + ('0' + MyDate.getDate()).slice(-2);
  formData.append("datum", datum);
  formData.append("fn", fn);
  formData.append("nachweis_blob", blob_data);


  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((response) => {
    console.log(response);
  }).catch((error) => {
    console.log(error);
  })
}

function verifyLocationId(locationId, callback) {
  const route = "/get_teststelle.php"
  axios.get(protocol + host + route, {
    params: {
      teststelle_id: locationId
    }
  }).then((response) => {
    const location = {
      id: response.data.teststelle_id,
      name: response.data.t_name,
      street: response.data.t_strasse,
      postcode: response.data.t_plz,
      city: response.data.t_ort
    }
    callback(location, null)
  }).catch((error) => {
    callback(null, error)
  });
}

function customerAcceptance(customerId, code, signatureData, callback) {
  const route = "/put_durchfuehrung_bestaetigt.php"
  const formData = new FormData();
  formData.append("kunde_id", customerId);
  formData.append("code", code);
  formData.append("k_unterschrift2", signatureData);

  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((response) => {
    callback(response.data, null);
  }).catch((error) => {
    callback(null, error);
  })
}

function getCustomer(customerId, callback) {
  const route = '/get_termin.php'

  axios.get(protocol+host+route, {
    headers: {
      'Authorization': bearer + userSessionToken
    },
    params: {
      kunde_id: customerId
    }
  }).then((response) => {
    console.log(response);
    callback(response.data, null);
  }).catch((error) => {
    callback(null, error);
  });
}

function sendLegitimization(customer, callback) {
  console.log(customer)
  const route = '/legitimieren.php'
  const formData = new FormData();

  formData.append('kunde_id', customer.kunde_id);
  formData.append('k_vorname', customer.k_vorname);
  formData.append('k_nachname', customer.k_nachname);
  formData.append('k_strasse', customer.k_strasse);
  formData.append('k_plz', customer.k_plz);
  formData.append('k_ort', customer.k_ort);
  formData.append('k_telefon', customer.k_telefon);
  formData.append('k_email', customer.k_email);
  formData.append('k_geburtstag', customer.k_geburtstag);
  formData.append('k_ausweisnummer', customer.k_ausweisnummer);
  formData.append('k_cwa_einverstanden', customer.k_cwa_einverstanden);
  formData.append('k_testgrund', customer.k_testgrund);
  formData.append('k_anmeldedatum', customer.k_anmeldedatum);
  formData.append('k_session_id', sessionId);
  formData.append('k_testgrund_detail', customer.k_testgrund_detail);
  formData.append('k_testgrund_detail_desc', customer.k_testgrund_detail_desc);

  formData.append('k_geburtsort', customer.k_geburtsort);
  formData.append("k_vorname_vertreter", customer.k_vorname_vertreter);
  formData.append("k_nachname_vertreter", customer.k_nachname_vertreter);
  formData.append("k_geburtstag_vertreter", customer.k_geburtstag_vertreter);
  formData.append("k_strasse_vertreter", customer.k_strasse_vertreter);
  formData.append("k_plz_vertreter", customer.k_plz_vertreter);
  formData.append("k_ort_vertreter", customer.k_ort_vertreter);

  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': bearer + userSessionToken
    },
  }).then((response) => {
    console.log(response);
  }).catch((error) => {
    console.log(error);
  })
}

function updateAppointments(locadtionId, date, highestCustomerId, callback) {
  const route = "/termine_aktualisieren.php"
  axios.get(protocol+host+route, {
    headers: {
      'Authorization': bearer + userSessionToken
    },
    params: {
      teststelle_id: locadtionId,
      k_anmeldedatum: date,
      kunde_id: highestCustomerId
    }
  }).then((response) => {
    const data = response.data[0];
    console.log(data);
    callback(data.durchgefuehrte_ids, data.legitimiert_ids, data.neue_kunden);
  }).catch((error) => {
    console.log(error);
  })
}

function startTest(customerId, callback) {
  const route = "/termin_starten.php"
  const formData = new FormData();
  formData.append("kunde_id", customerId);

  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': bearer + userSessionToken
    },
  }).then((response) => {
    console.log(response);
    callback(response.data, null);
  }).catch((error) => {
    console.log(error);
    callback(null, error);
  })
}

function getTestKits(callback) {
  const route = "/get_testkits.php";
  console.log(userSessionToken);
  axios.get(protocol+host+route, {
    headers: {
      'Authorization': bearer + userSessionToken
    },
  }).then((response) => {
    console.log(response);
    callback(response.data, null);
  }).catch((error) => {
    console.log(error)
    callback(null, error);
  })
}

function setSessionSettings(testkit_id, tester_name, signatureData, callback) {
  const route = "/put_session_settings.php"
  const formData = new FormData();
  formData.append("session_testkit_id", testkit_id);
  formData.append("tester_name", tester_name);
  formData.append("tester_unterschrift", signatureData);

  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': bearer + userSessionToken
    },
  }).then((response) => {
    console.log(response);
    storage.setItem('sessionId', response.data[0].session_id);
    sessionId = storage.getItem('sessionId');
    callback(response.data, null);
  }).catch((error) => {
    console.log(error);
    callback(null, error);
  })
}

function sendTestResult(customerId, testResult, callback) {
  const route = '/ergebnis_mitteilen.php';
  const formData = new FormData();

  formData.append("kunde_id", customerId);
  formData.append("k_ergebnis", testResult);

  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': bearer + userSessionToken
    },
  }).then((response) => {
    callback(response.data, null);
  }).catch((error) => {
    callback(null, error);
  })
}

function getTagesreport(locationId, date, callback) {
  const route = "/gen_tagesreport.php";
  const formData = new FormData();
  formData.append("teststelle_id", locationId);
  formData.append("date", date);

  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': bearer + userSessionToken
    },
    responseType: 'blob'
  }).then((response) => {
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'tagesreport_'+locationId+'_'+date+'.pdf');
    document.body.appendChild(link);
    link.click();
  }).catch((error) => {
    console.log(error);
  })
}


function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}
function getWochenreport(locationId, date, callback) {
  const route = "/gen_wochenreport.php";
  const formData = new FormData();
  formData.append("teststelle_id", locationId);
  formData.append("date", date);

  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': bearer + userSessionToken
    },
    responseType: 'blob'
  }).then((response) => {
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'wochenreport_'+locationId+'_KW'+getWeekNumber(new Date(Date.parse(date)))[1]+'_'+getWeekNumber(new Date(Date.parse(date)))[0]+'.pdf');
    document.body.appendChild(link);
    link.click();
  }).catch((error) => {
    console.log(error);
  })
}

function getPositive(locationId, date, callback) {
  const route = "/gen_positive.php";
  const formData = new FormData();
  formData.append("teststelle_id", locationId);
  formData.append("date", date);

  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': bearer + userSessionToken
    },
    responseType: 'blob'
  }).then((response) => {
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'positivreport_'+locationId+'_'+date+'.pdf');
    document.body.appendChild(link);
    link.click();
  }).catch((error) => {
    console.log(error);
  })
}

function setValidPLZ(callback) {
  const route = "/get_plz.php";
  axios.get(protocol+host+route, {
    headers: {
      'Authorization': bearer + userSessionToken
    },
  }).then((response) => {
    console.log(response);
    callback(response.data, null);
  }).catch((error) => {
    console.log(error)
    callback(null, error);
  })
}

function deleteKunde(kunde_id, callback) {
  const route = '/delete_kunde.php';
  const formData = new FormData();

  formData.append('kunde_id', kunde_id);

  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': bearer + userSessionToken
    },
  }).then((response) => {
    callback(response.data, null);
  }).catch((error) => {
    callback(null, error);
  })
}

function getCountJSON(adminId, callback) {
  const route = '/count/count_tests_with_positive.php?adminId='+adminId;

  axios.get(protocol+host+route, {
    headers: {
      'Authorization': bearer + userSessionToken
    },
  }).then((response) => {
    console.log(response);
    callback(response.data, null);
  }).catch((error) => {
    callback(null, error);
  });
}

function getEditCustomerJSON(date, callback) {
  const route = '/get_edit_customers.php';

  axios.get(protocol+host+route, {
    headers: {
      'Authorization': bearer + userSessionToken
    },
    params: {
      k_anmeldedatum: date,
      admin_id: storage.getItem('adminId')
    }
  }).then((response) => {
    console.log(response);
    callback(response.data, null);
  }).catch((error) => {
    callback(null, error);
  });
}

function sendEditTestResult(customer, callback) {
  const route = '/korrigieren.php';
  const formData = new FormData();

  formData.append('kunde_id', customer.kunde_id);
  formData.append('k_vorname', customer.k_vorname);
  formData.append('k_nachname', customer.k_nachname);
  formData.append('k_strasse', customer.k_strasse);
  formData.append('k_plz', customer.k_plz);
  formData.append('k_ort', customer.k_ort);
  formData.append('k_telefon', customer.k_telefon);
  formData.append('k_email', customer.k_email);
  formData.append('k_geburtstag', customer.k_geburtstag);
  formData.append('k_ausweisnummer', customer.k_ausweisnummer);
  formData.append('k_cwa_einverstanden', customer.k_cwa_einverstanden);
  formData.append('k_testgrund', customer.k_testgrund);
  formData.append('k_anmeldedatum', customer.k_anmeldedatum);
  formData.append('k_session_id', sessionId);
  formData.append('k_testgrund_detail', customer.k_testgrund_detail);
  formData.append('k_testgrund_detail_desc', customer.k_testgrund_detail_desc);

  formData.append('k_geburtsort', customer.k_geburtsort);
  formData.append("k_vorname_vertreter", customer.k_vorname_vertreter);
  formData.append("k_nachname_vertreter", customer.k_nachname_vertreter);
  formData.append("k_geburtstag_vertreter", customer.k_geburtstag_vertreter);
  formData.append("k_strasse_vertreter", customer.k_strasse_vertreter);
  formData.append("k_plz_vertreter", customer.k_plz_vertreter);
  formData.append("k_ort_vertreter", customer.k_ort_vertreter);

  axios.post(protocol+host+route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': bearer + userSessionToken
    },
  }).then((response) => {
    callback(response.data, null);
  }).catch((error) => {
    callback(null, error);
  })
}

export { loginUser, getLocations, getAppointments, getDailyReportFacts, registerAppointment, verifyLocationId, 
  customerAcceptance, getCustomer, sendLegitimization, updateAppointments, startTest, getTestKits, 
  setSessionSettings, sendTestResult, getTagesreport, getWochenreport, getPositive, getCWA, setValidPLZ, deleteKunde,
  uploadNachweis, getCountJSON, getEditCustomerJSON, sendEditTestResult };
