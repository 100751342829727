<template>
  <div>
    <b-alert :show="errored" variant="danger">{{error}}</b-alert>
    <h1 class="text-center">Bitte melden Sie sich an</h1>
    <b-form @submit="onSubmit" class="login-container m-auto">
      <b-form-group
        class="mt-3"
        id="input-group-email"
        label="Email-Adresse:"
        label-for="input-eMail"
      >
        <b-form-input
          id="input-eMail"
          v-model="form.email"
          type="email"
          placeholder="Gebe die E-Mail-Adresse ein"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        class="mt-3"
        id="input-group-password"
        label="Passwort:"
        label-for="input-password"
      >
        <b-form-input
          id="input-password"
          v-model="form.password"
          type="password"
          placeholder="Gebe das Passwort ein"
          required
        ></b-form-input>
      </b-form-group>
      <b-button class="mt-4" type="submit" variant="outline-success"
        >Anmelden</b-button
      >
    </b-form>
  </div>
</template>

<script>
import { loginUser } from "@/client";

export default {
  name: "LoginComponent",
  data() {
    return {
      errored: false,
      error: null,
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      loginUser(this.form.email, this.form.password, (userToken, error) => {
        if(error){
          this.error = error;
          this.errored = true;
        } else {
          this.$store.dispatch('setUserToken', userToken);
        }
      });
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
}
</style>
