<template>
  <b-table v-if="!errored" :items="$store.state.testCenter.locations" :fields="fields" striped hover>
    <template #cell(bearbeiten)="row">
      <b-button variant="outline-secondary" @click="editLocation(row.index)">
        <b-icon-pen/>
      </b-button>
    </template>
    <template #cell(Buchungslink)="row">
      <b-button variant="outline-secondary" @click="showBuchungslink(row.index)"><b-icon-eye/></b-button>
    </template>
    <template #cell(Terminuebersicht)="row">
      <b-button variant="outline-secondary" @click="showAppointments(row.index)"><b-icon-calendar/></b-button>
    </template>
  </b-table>
  <b-alert variant="danger" v-else :show="errored">
    Es trat ein Fehler beim Laden der Teststellen auf:
    <p>{{error}}</p>
  </b-alert>
</template>

<script>
import { getLocations } from "@/client";

export default {
  name: "TestCenterLocationList",
  data () {
    return {
      fields: [
        {
          key: "name",
          label: "Name"
        },
        {
          key: "postcode",
          label: "Postleitzahl"
        },
        {
          key: "city",
          label: "Stadt"
        },
        {
          key: "bearbeiten",
        },
        {
          key: "Buchungslink",
        },
        {
          key: "Terminuebersicht",
          label: "Terminübersicht"
        },
      ],
      errored: false,
      error: null,
    }
  },
  beforeMount() {
    getLocations((testCenter, error) => {
      if(error) {
        this.errored = true;
        this.error = error;
      } else {
        this.$store.dispatch("setTestCenter", testCenter);
      }
    })
  },
  methods: {
    editLocation(locationIndex) {
      let location = this.$store.getters.getLocationFromIndex(locationIndex);
      this.$router.push({path: '/edit/location/' + location.id});
    },
    showAppointments(locationIndex) {
      let location = this.$store.getters.getLocationFromIndex(locationIndex);
      this.$router.push({path: '/location/' + location.id + '/appointments'});
    },
    showBuchungslink(locationIndex) {
      let location = this.$store.getters.getLocationFromIndex(locationIndex);
      window.open('/?checkbox-information=true#/registration/' + location.id, '_blank');
    }
  }
};
</script>

<style>
  td {
    vertical-align: baseline;
    text-align: center;
  }
  tr {
    text-align: center;
  }
</style>
